import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import Slideshow from 'react-slick'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import PageContainer from '@system/page-container'
import '../../styles/slick.css'
import '../../styles/slick-theme.css'

const useStyles = makeStyles((theme) => ({
  heroCarouselContainer: {
    '& .MuiContainer-root li': {
      background: theme.palette.common.white,
      width: '55px',
      height: '4px',
      '&.slick-active': {
        background: theme.palette.legacy.primary,
      },
    },
    '& .slick-active': {
      zIndex: '100 !important',
    },
  },
}))

const HeroCarousel = (props) => {
  const classes = useStyles()

  const appendDots = (dots) => (
    <Box
      css={{
        textAlign: 'left',
        bottom: '5%',
      }}
    >
      <ul className="MuiContainer-root MuiContainer-fixed MuiContainer-maxWidthLg">
        {dots}
      </ul>
    </Box>
  )

  const settings = {
    autoplay: props.blok.autoplay ? props.blok.autoplay : true,
    autoplaySpeed: props.blok.timePerFrame
      ? props.blok.timePerFrame * 1000
      : 10000,
    speed: 1200,
    dots: true,
    customPaging: (i) => <Box width="100%" height="100%"></Box>,
    lazyLoad: 'progressive',
    infinite: true,
    fade: true,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    initialSlide: 0,
    appendDots: appendDots,
  }
  return (
    <SbEditable content={props.blok}>
      <PageContainer parentWidth={!props.blok.isPageWidth}>
        <Box className={classes.heroCarouselContainer}>
          <Slideshow {...settings}>
            {props.blok.frames.map((blok) => (
              <Box key={blok._uid}>{renderBlok(blok)}</Box>
            ))}
          </Slideshow>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default HeroCarousel
